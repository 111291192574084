<template>
    <div>
        <div class="flex"><h4>经营日报</h4></div>
        <div class="flex count">
            <div class="flex">
                <div class="flex countShortcut">
                    日期: 
                </div>
                <a-date-picker v-model="date" @change="onChange"></a-date-picker>
            </div>
            
        </div>
        <div class="pannel">
            <div class="countTitle">
                <!-- <h4>客户数据</h4> -->
                <a-row :gutter="16">
                    <a-col :span="8">
                        <a-card title="客户数据">
                            <div class="content flex">
                                <p>新客数<br><span class="content_count">{{corp.new}}</span></p>
                                <p>新跟进数<br><span class="content_count">{{corp.follow}}</span></p>
                                <p>新商机数<br><span class="content_count">{{corp.chance}}</span></p>
                            </div>
                        </a-card>
                    </a-col>
                    <a-col :span="8">
                        <a-card title="订单数据" >
                            <div class="content flex">
                                <p>订单数量<br><span class="content_count">{{order.num}}</span></p>
                                <p>订单金额<br><span class="content_count">{{order.amount}}</span></p>
                                <p>客单价<br><span class="content_count">{{order.price}}</span></p>
                            </div>
                        </a-card>
                    </a-col>
                    <a-col :span="8">
                        <a-card title="金额数据">
                            <div class="content flex"  style="height:40px;line-height:40px">
                                <p>收款数<span class="content_count">{{order.payment_num}}</span></p>
                                <p>收款金额<span class="content_count">{{order.payment_amount}}</span></p>
                            </div>
                            <div class="content flex"  style="height:40px;line-height:40px">
                                <p>退款数<span class="content_count">{{order.refund_num}}</span></p>
                                <p>退款金额<span class="content_count">{{order.refund_amount}}</span></p>
                            </div>
                        </a-card>
                    </a-col>
                </a-row>
            </div>
            <div class="flex">
                <div style="width:80%">
                    <div class="text-align:left">
                        <h4>收款列表</h4>
                        <a-table  :columns="columns" :data-source="list.payment" class="table" :pagination="false" ></a-table>
                    </div>
                    <div class="">
                        <h4>退款列表</h4>
                        <a-table :columns="columns2" :data-source="list.refund" class="table" :pagination="false" ></a-table>
                    </div>
                </div>
                <div class="ranking">
                    <div class="flex" style="align-items:center">
                        <h4 @click="rankChange(1)" :style="type == 1 ? 'font-size:20px;font-weight:bold' : 'font-size:16px'">收款排行榜</h4>
                        <h4 @click="rankChange(2)" :style="type == 2 ? 'font-size:20px;font-weight:bold' : 'font-size:16px'">退款排行榜</h4>
                    </div>
                    <div class="ranking_bottom">
                        <a-list :data-source="ranking">
                            <a-list-item slot="renderItem" slot-scope="item,index">
                                <div class="flex ranking_content" >
                                    <span>
                                        <span v-if="index < 3">
                                        <img v-if="index == 0" src="@/assets/imgs/one.png" alt="">
                                        <img v-else-if="index == 1" src="@/assets/imgs/two.png" alt="">
                                        <img v-else-if="index == 2" src="@/assets/imgs/three.png" alt="">
                                    </span>
                                    <span class="ranking_num" v-else>{{index+1}}</span>
                                    {{item.staff}}
                                    </span>
                                    <span :class="[index<3? 'yellow' : '']">{{item.amount}}元</span>
                                </div>
                            </a-list-item>
                        </a-list>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import * as echarts from 'echarts';
import {getDateTime, requestXml} from '../../../assets/js/request';
var moment = require('moment');
export default {
    name: 'envyPie',
    props: {
        voltage: Array
    },
    data() {
        return {
            date: "",
            corp: {},
            order: {},
            list: {},
            ranking: [],
            payment: [],
            refund: [],

            type: 1,

            columns:[
                { title: '客户名称', dataIndex: 'name'},
                { title: '订单编号', dataIndex: 'osn'},
                { title: '收款金额', dataIndex: 'amount' },
                { title: '创建时间', dataIndex: 'created' },
            ],
            columns2:[
                { title: '客户名称', dataIndex: 'name'},
                { title: '订单编号', dataIndex: 'osn'},
                { title: '退款金额', dataIndex: 'amount' },
                { title: '创建时间', dataIndex: 'payment_amount' },
            ],
        }
    },
    mounted: function() {
        this.date = moment();
        // 获取经营日报
        this.getPannel();

        if(this.$route.query.day){
            this.date = this.$route.query.day;
        }
        
    },
    methods: {
        onChange(value) {
            console.log(value)
            this.getPannel();
        },
        getPannel(){
            var date = this.date._d.getFullYear() + "-" + Number(this.date._d.getMonth()+1)  + "-" + this.date._d.getDate();
            requestXml("/scrm/Statistics/getBusinessDaily","POST",(res) => {
                // 经营日报
                for(let i=0;i<res.list.payment.length;i++){
                    res.list.payment[i].created = getDateTime(res.list.payment[i].created);
                }
                for(let i=0;i<res.list.refund.length;i++){
                    res.list.refund[i].created = getDateTime(res.list.refund[i].created);
                }
                this.corp = res.corp;
                this.order = res.order;
                this.ranking = res.ranking.payment;
                this.payment = res.ranking.payment;
                this.refund = res.ranking.refund;
                this.list = res.list;
            },{"day": new Date(date).valueOf()})
        },
        rankChange(type){
            this.type = type;
            console.log(this.ranking)
            if(type == 1){
                this.ranking = this.payment;
            }else{
                this.ranking = this.refund;
            }
        }
    },

}
</script>
<style scoped>
    h4{
        margin: 10px 0;
        font-weight: bold;
    }
    .count{
        margin: 20px;
        justify-content: space-between;
    }
    .count .countShortcut{
        align-items: center;
        color: #202020;
        font-weight: bold;
    }
    .pannel .countTitle{
        margin: 40px 0;
        align-items: baseline;
    }
    .pannel .countTitle .ant-card .ant-card-body{
        height: 80px;
        line-height: 30px;
        padding: 10px 20px;
    }
    .pannel .countTitle .ant-col:last-child .ant-card .ant-card-body{
        padding-top: 0;
    }
    .pannel .content{
        justify-content: space-between;
    }
    .pannel .content .content_count{
        display: inline-block;
        margin: 0 5px;
        font-weight: bold;
    }
    .ant-card-head-title{
        font-weight: bold;
    }
    .pannel .ranking{
        width: 20%;
    }
    .pannel .ranking h4{
        cursor: pointer;
        margin-right: 5px;
    }
    .pannel .ranking .ranking_content {
        width: 100%;
        align-items: center;
        justify-content: space-between;
        height: 60px;
        line-height: 60px;
        font-size: 18px;
        color: #161616;
        cursor: pointer;
    }
    .pannel .ranking .ranking_content img {
        width: 40px;
        height: 46px;
    }
    .pannel .ranking .ranking_content .ranking_num {
        width: 42px;
        height: 42px;
        line-height: 42px;
        text-align: center;
        color: #1f90ff;
        background: #d2e9ff;
        font-size: 22px;
        border-radius: 50%;
    }
</style>
